import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { minBreakpointQuery } from '../styles';
import Layout from '../components/Layout';
import BlogContent from '../components/BlogContent';
import BlogSidebar from '../components/BlogSidebar';

const StyledWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;

  ${minBreakpointQuery.smedium`
    display: grid;
    grid-template-columns: minmax(0, 2fr) 1fr;
  `}

  ${minBreakpointQuery.mlarge`
    grid-template-columns: minmax(0, 3fr) 1fr;
  `}
`;

const BlogSingleTemplate = ({
  data: {
    datoCmsBlog: {
      seoMetaTags,
      title,
      slug,
      featuredImage,
      meta: { firstPublishedAt },
      modularBlocks,
    },
    allShopifyProduct: { shopifyProducts },
    allDatoCmsBlog: { nodes },
  },
}) => (
  <Layout seo={seoMetaTags}>
    <main>
      <StyledWrapper>
        <BlogContent
          title={title}
          firstPublishedAt={firstPublishedAt}
          items={modularBlocks}
          image={featuredImage}
          slug={slug}
          shopifyProducts={shopifyProducts}
        />
        <BlogSidebar items={nodes} />
      </StyledWrapper>
    </main>
  </Layout>
);

export const BlogSingleTemplateQuery = graphql`
  query BlogSingleTemplateQuery($id: String!, $productHandles: [String!]) {
    datoCmsBlog(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      slug
      meta {
        firstPublishedAt(formatString: "DD MMM YYYY")
      }
      featuredImage {
        gatsbyImageData(width: 1100, height: 380)
        alt
      }
      modularBlocks {
        ...ContentModularBlockFragment
        ...ContainedImageModularBlockFragment
        ...FullWidthImageModularBlockFragment
        ...TableModularBlockFragment
      }
    }
    allShopifyProduct(filter: { handle: { in: $productHandles } }) {
      shopifyProducts: nodes {
        ...ProductCardFragment
      }
    }
    allDatoCmsBlog(
      limit: 3
      sort: { order: DESC, fields: meta___firstPublishedAt }
      filter: { id: { ne: $id } }
    ) {
      nodes {
        title
        slug
        featuredImage {
          gatsbyImageData(width: 290, height: 250)
          alt
        }
        meta {
          firstPublishedAt(formatString: "DD MMM YYYY")
        }
      }
    }
  }
`;

export default BlogSingleTemplate;
