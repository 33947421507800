import React from 'react';
import styled from 'styled-components';
import { fontSize, maxBreakpointQuery, visuallyHidden } from '../styles';
import { Container, Svg } from './ui';
import facebookIcon from '../images/facebook-icon.inline.svg';
import twitterIcon from '../images/twitter-icon.inline.svg';
import linkedInIcon from '../images/linkedIn-icon.inline.svg';
import pinterestIcon from '../images/pinterest-icon.inline.svg';

const StyledShare = styled.div`
  margin-top: 70px;
  margin-bottom: 40px;
  ${maxBreakpointQuery.smedium`
    ${visuallyHidden()}
  `}
`;

const StyledInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledText = styled.p`
  ${fontSize(24)};
  margin-right: 15px;
`;

const StyledIcons = styled.div`
  display: flex;
  column-gap: 15px;
`;

const StyledIcon = styled.a``;

const StyledSvg = styled(Svg)`
  width: 42px;
  height: 42px;
  vertical-align: middle;
`;

const Share = ({ slug }) => (
  <StyledShare>
    <Container>
      <StyledInner>
        <StyledText>Share this article:</StyledText>
        <StyledIcons>
          <StyledIcon
            href={`https://www.facebook.com/sharer.php?u=${process.env.GATSBY_SITE_URL}/${slug}`}
            target="_blank"
          >
            <StyledSvg image={facebookIcon} />
          </StyledIcon>
          <StyledIcon
            href={`https://twitter.com/share?url=${process.env.GATSBY_SITE_URL}/${slug}`}
            target="_blank"
          >
            <StyledSvg image={twitterIcon} />
          </StyledIcon>
          <StyledIcon
            href={`https://www.linkedin.com/shareArticle?mini=true&url=${process.env.GATSBY_SITE_URL}/${slug}`}
            target="_blank"
          >
            <StyledSvg image={linkedInIcon} />
          </StyledIcon>
          <StyledIcon
            href={`https://www.pinterest.co.uk/pin/create/link/?url=${process.env.GATSBY_SITE_URL}/${slug}`}
            target="_blank"
          >
            <StyledSvg image={pinterestIcon} />
          </StyledIcon>
        </StyledIcons>
      </StyledInner>
    </Container>
  </StyledShare>
);

export default Share;
