import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
  brandFonts,
  breakpointSizes,
  fluidFontSize,
  fontSize,
  fontWeights,
  minBreakpointQuery,
  sectionMargins,
  standardColours,
} from '../styles';
import { Container, Svg } from './ui';
import ModularBlocks from './ModularBlocks';
import FeaturedProducts from './FeaturedProducts';
import Share from './Share';
import calendarIcon from '../images/calendar.inline.svg';

const StyledBlogContent = styled.div``;

const StyledImage = styled(GatsbyImage)``;

const StyledInner = styled.div`
  ${sectionMargins('20px', '40px')}
`;

const StyledHeading = styled.h1`
  font-family: ${brandFonts.secondary};
  color: ${standardColours.black};
  ${fluidFontSize(
    '20px',
    '36px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
  font-weight: ${fontWeights.regular};
  margin-bottom: 15px;
`;

const StyledPublishedDate = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Svg)`
  width: 10px;
  height: 10px;
  margin-right: 10px;

  ${minBreakpointQuery.medium`
    width: 16px;
    height: 16px;
  `}
`;

const StyledDate = styled.p`
  ${fontSize(12)};
  color: ${standardColours.black};
  line-height: 1.6;

  ${minBreakpointQuery.mlarge`
    ${fontSize(14)};
  `}
`;

const BlogContent = ({
  title,
  firstPublishedAt,
  items,
  image,
  slug,
  shopifyProducts,
}) => (
  <StyledBlogContent>
    <Container>
      <StyledImage image={image.gatsbyImageData} alt={image.alt} />
      <StyledInner>
        <StyledHeading>{title}</StyledHeading>
        <StyledPublishedDate>
          <StyledIcon image={calendarIcon} />
          <StyledDate>{firstPublishedAt}</StyledDate>
        </StyledPublishedDate>
      </StyledInner>
    </Container>
    <ModularBlocks items={items} isBlogSingle={true} />
    <FeaturedProducts
      heading="Products Mentioned in This Article:"
      items={shopifyProducts}
      isBlogSingle={true}
    />
    <Share slug={slug} />
  </StyledBlogContent>
);

export default BlogContent;
