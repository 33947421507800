import React from 'react';
import styled from 'styled-components';
import {
  brandFonts,
  fontSize,
  fontWeights,
  maxBreakpointQuery,
  minBreakpointQuery,
  standardColours,
} from '../styles';
import BlogCard from './BlogCard';

const StyledBlogSidebar = styled.div`
  ${maxBreakpointQuery.smedium`
    margin: 55px 0;
  `}

  ${minBreakpointQuery.smedium`
    margin-bottom: 55px;
  `}
`;

const StyledInner = styled.div`
  display: grid;
  row-gap: 50px;
  background-color: ${standardColours.lightGrey};
  padding: 40px 20px;
  margin-right: 30px;

  ${maxBreakpointQuery.smedium`
    margin-left: 30px;
  `}

  ${minBreakpointQuery.smedium`
    row-gap: 25px;
  `}
`;

const StyledHeading = styled.h4`
  ${fontSize(20)};
  font-family: ${brandFonts.secondary};
  font-weight: ${fontWeights.regular};
`;

const StyledBlogCard = styled(BlogCard)`
  ${maxBreakpointQuery.smedium`
    padding: 0 15px;
  `}
`;

const BlogSidebar = ({ items }) => {
  return (
    <StyledBlogSidebar>
      <StyledInner>
        <StyledHeading>Related Articles:</StyledHeading>
        {items.map(
          ({ title, slug, featuredImage, meta: { firstPublishedAt } }, id) => (
            <StyledBlogCard
              key={id}
              heading={title}
              slug={slug}
              image={featuredImage}
              firstPublishedAt={firstPublishedAt}
              isSidebar={true}
            />
          )
        )}
      </StyledInner>
    </StyledBlogSidebar>
  );
};

export default BlogSidebar;
